import { navigateTo, defineNuxtRouteMiddleware } from '#imports';

const { isLoggedIn } = useUserStore()

export default defineNuxtRouteMiddleware((_to, _from) => {
	const localePath = useLocalePath()

	if (!isLoggedIn && import.meta.client) {
		return navigateTo(localePath('/auth'));
	}
});
